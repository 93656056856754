export const socialsData = {
    github: 'https://github.com/dorokah',
    facebook: 'https://www.facebook.com/dor.rokah',
    linkedIn: 'https://www.linkedin.com/in/dor-rokah-5293851a0/',
    instagram: 'https://www.instegram.com/dorokah',
    codepen: '',
    twitter: '',
    reddit: '',
    blogger: '',
    medium: '',
    stackOverflow: '',
    gitlab: '',
    youtube: ''
}